<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline>
      <el-form-item label="类型：" class="form-item" prop="type">
        <el-select v-model="form.type" placeholder="请选择" style="width: 120px">
          <el-option label="全部" value=""></el-option>
          <el-option label="建议" :value="2"></el-option>
          <el-option label="反馈" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户ID：" class="form-item" prop="customerId">
        <el-input
          placeholder="请输入"
          class="form-item-input"
          v-model="form.customerId"
          style="width: 120px"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户手机号：" class="form-item" prop="phone">
        <el-input
          placeholder="请输入"
          class="form-item-input"
          style="width: 120px"
          v-model="form.phone"
        ></el-input>
      </el-form-item>
      <el-form-item label="提交时间：" class="form-item">
        <el-date-picker
          style="width: 320px"
          v-model="time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          class="form-item-time"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item class="form-item" label-width="30px">
        <el-button size="small" type="primary" @click="search">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'AdviceSearch',
  components: {},
  data() {
    return {
      time: '',
      form: {
        type: '',
        phone: '',
        customerId: '',
        createTimeStart: '',
        createTimeEnd: ''
      }
    };
  },
  watch: {
    time(newVal, oldVal) {
      if (newVal) {
        this.form.createTimeStart = dayjs(newVal[0]).format('YYYY-MM-DD HH:mm:ss');
        this.form.createTimeEnd = dayjs(newVal[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.form.createTimeStart = '';
        this.form.createTimeEnd = '';
      }
    }
  },
  methods: {
    search() {
      this.$emit('search', this.form);
    },
    reset() {
      this.time = '';
      this.form.createTimeStart = '';
      this.form.createTimeEnd = '';
      this.form.phone = '';
      this.form.customerId = '';
      this.form.type = '';
      this.$emit('reset', this.form);
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
